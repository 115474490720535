import {useState, useEffect} from 'react';
import { payKey } from '../Card/store.js'
import { useNavigate } from 'react-router-dom';
import './payment.css'
import Data from './Data.js';
import axios from 'axios';
import { mesKey } from '../Card/storeMes.js';
import { Link } from 'react-router-dom';



const Pay = ()=>{
  const [info, setInfo] = useState('')
  const [bol, setBol] = useState('')
  const [tel, setTel] = useState('')
  const [detail, setDetail] = useState()




  const amounts = [ 5, 10, 15 ];
  const [ amount, setAmount ] = useState( null ||'');
  const [ isCustomAmount, setIsCustomAmount ] = useState(false);
  const [modalActive, setModalActive] = useState(false)
  const [cur, setCur] = useState('Currency')  

    useEffect(()=>{
      fetch('https://murtaza-bot.ru/bot/')
      .then(response => response.json())
      .then(data => {
        setTel(data[0].bot_url)
      })
      .catch(error => console.error(error));
    
    },[])

   useEffect(()=>{
    fetch('https://murtaza-bot.ru/pay/type/')
    .then(response => response.json())
    .then(data => {
    setInfo(data[0].merchent_id),
    setBol(data[0].test)
    setDetail(data[0].detail)
    })
    .catch(error => console.error(error));
   },[])



    const navigate = useNavigate()

    function handleSubmit(event){
      const article = {
         chat_id: payKey.data, 
         message_id: mesKey.data
        };

      axios.post('https://murtaza-bot.ru/delete/', article)
      const input1 = event.target.email.value;
      const input2 = event.target.amountusd.value
      const input3 = event.target.currency.value

      if (!input1 || !input2 ) {
        navigate('/error');
        return;
      }
       else if(input3 === 'Currency'){
        navigate('/error');
        return;
      }
      else(
        event.target.submit()
      )
    }

    function Right(){
      setModalActive(true)
    }

    
    return(
        <>
             <div className='wrapper2'>
     
          <div className='container2'>
          
        
 
          <form action="https://app.0xprocessing.com/Payment" method="post" onSubmit={handleSubmit}>
    
                <input  type="hidden" name="test"  value={bol} />
        
             <input type="email" name='email'  placeholder='Email' />
             
              <label className='btn'>
                <input   type="radio" onClick={Right}  placeholder='Currency'  autoComplete="off" />
                <span>{cur}</span>
                </label>
             
        <div className={modalActive ? 'modal active' : 'modal'} onClick={()=>setModalActive(false)}>
          <div className='content' onClick={e => e.stopPropagation()}>
            <h1>Select currency</h1>
            
             {
                  Data. map((value)=>{
                    return(
                      <div className='box1' key={value.id} onClick={() =>  {setModalActive(false); setCur(value.name)}}>
                        <img src={value.img} alt="logo" />
                        <h4>{value.name}</h4>
                        </div>
                    )
                  })  
              }
           
          </div>
        </div>


   <div className='mid'>
  <div className='choice'>
    {amounts.map((value)=>{
      return(
        <label  key={value}>
          <input  name="key"  type="radio" value={value}
                 onChange={() => {
                   setIsCustomAmount(false);
                   setAmount(value)
                 }}
          />
          <span>${value}</span>
        </label>
      )
    })}
    <label  key={'any'}>
      <input   name="key"  type="radio" value={'any'}
             onChange={(e) => {
               setIsCustomAmount(true);
               setAmount(''); 
             }}
      />
      <span style={{fontSize:'10px'}}>{'Other amount'}</span> </label>


  </div>

  {isCustomAmount &&
  <div className='foo'>
    <input value={amount === 0? '' : amount} type='number'
   onChange={e => setAmount(+e.target.value)} 
   min="5" max="2000"
   step="any"
   required
 />
 </div>}


</div>

        
              <input type="hidden" name="ShopId" value={info} />
    
            
              <input type="hidden" name="ClientId" value={payKey.data} />
              <input type="hidden" name='SuccessUrl' value={tel}/>
              <input type="hidden" name='AutoReturn' value="true" />
              <input type="hidden" name='amountusd' value={amount}/>
              <input type="hidden" name='currency'  value={cur}/>

  
              <div className='rules'>
              <div dangerouslySetInnerHTML={{ __html: detail }}/>
              </div>
            
        
              <button className='btn2' type="submit">Pay</button>
          </form>   
       
            
    
          </div>
        
         </div>
  
       
        <div className='heading'><a  href={`/?id=${payKey.data}&mes_id=${mesKey.data}`}>
       <button>Back</button> 
        </a>
       </div>
  
       

        </>
    )

}

export default Pay
